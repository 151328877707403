import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { Observable } from 'rxjs';
import { AuthService, UserType } from '../../services/auth.service';
import { UserModel } from '../../services/user-model';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { Menu } from '../../interfaces/interfaces';
import { getEnvironment } from '../../environment/environment';

declare var $: any;

@Component({
  selector: 'main-layout',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    SidebarComponent,
    HeaderComponent,
  ],
})
export class MainLayoutComponent implements OnInit {

  @ViewChild('cttMain') cttMain?: ElementRef;

  user$: Observable<UserType>;
  title_page: any = '';

  menu: Menu[] = [];

  env = getEnvironment();

  nameTitleMenuAbonos:string = 'Abono';
  nameTitleMenuUsuarioInscripto:string = 'Registrar usuario/inscrito';

  constructor(
    private authService: AuthService,
    public userModel: UserModel
  ) {
    
    this.nameTitleMenuAbonos = 'Abono';
    this.nameTitleMenuUsuarioInscripto = 'Registrar usuario/inscrito';

    this.user$ = this.authService.user$;
    this.user$.subscribe((data) => {
      this.menu = [
      ];

      if (data) {
        //console.log(data, "***");
        (data['user']['permissions'] ?? []).forEach((permission: string) => {

          switch (permission) {
            case 'page-cashier-tickets':
              this.menu.push({ name: 'Caja Tickets', url: '/cashier-tickets', external: false, icon: 'fa-light fa-users-medical', iconType: 'icon' })
              break;
            case 'page-history-tickets':
              this.menu.push({ name: 'Historial de Tickets', url: '/history-tickets', external: false, icon: 'fa-light fa-users-medical', iconType: 'icon' })
              break;
            case 'page-cashier-orders':
              this.menu.push({ name: 'Ventas directas', url: '/cashier-orders', external: false, icon: 'fa-light fa-users-medical', iconType: 'icon' })
              break;
            case 'page-history-orders':
              this.menu.push({ name: 'Historial de Pedidos', url: '/history-order', external: false, icon: 'fa-light fa-users-medical', iconType: 'icon' })
              break;
            /* case 'orders-presale':
              this.menu.push({ name: 'Pre-ventas', url: '/orders-presale', external: false, icon: 'fa-light fa-users-medical', iconType: 'icon' })
              break; */
            case 'orders-presale':
              this.menu.push({ name: 'Seguimiento de pedido', url: '/order-tracking', external: false, icon: 'fa-light fa-users-medical', iconType: 'icon' })
              break;

            default:
              break;
          }
        })

      }
    })


  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    $(function () {
      $('.toogle_dropdown').on('click', function (this: any) {
        $(this).next('.dropdown').slideToggle();
      });

      $('.toogle_dropdown_a').on('click', function (this: any) {
        $('.dropdown').slideUp()
      });

      $(document).on('click', function (e: any) {
        var target = e.target;
        if (!$(target).is('.toogle_dropdown') && !$(target).parents().is('.toogle_dropdown')) {
          $('.dropdown').slideUp()
        }
      });

      $('.content_header-space').css('padding-top', `${$('.header__menu').outerHeight()}px`);

      $('.btn_a-sidebar').on('click', function () {
        $('.bg__sidebar_left').fadeOut();
        $('.sidebar_left').removeClass('active');
      });
    });

    $('.btn__sidebar, .bg__sidebar_left').on('click', function () {
      $('.bg__sidebar_left').fadeToggle();
      $('.sidebar_left').toggleClass('active');
    });
  }

}
